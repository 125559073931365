import { Chains } from "./constants";

export class Utils {
  static getUniCode = (zeroCnt: number) => {
    const baseUnicode = 0x2080; // Base Unicode point for the digits
    const digits = zeroCnt.toString().split(""); // Split the number into digits
    return digits
      .map((digit: string) =>
        String.fromCharCode(baseUnicode + parseInt(digit))
      )
      .join("");
  };

  static formatNumber = (
    value: number,
    isInteger = false,
    isAxis = false,
    isTitle = false
  ) => {
    let isMinus = false;
    if (value < 0) {
      isMinus = true;
      value = -1 * value;
    }
    if (isInteger) value = Math.round(value);
    if (value === 0) {
      return 0;
    } else if (value < 0.01) {
      const zeroCnt = Math.ceil(Math.log10(1 / value)) - 1;
      let numberPart = value * Math.pow(10, zeroCnt + 3);
      if (numberPart % 100 === 0) numberPart = numberPart / 100;
      else if (numberPart % 10 === 0) numberPart = numberPart / 10;

      if (isTitle) {
        return `${isMinus ? "-" : ""}0.0${Utils.getUniCode(
          zeroCnt
        )}${numberPart.toFixed(0)}`;
      }

      if (isAxis) {
        return `${isMinus ? "-" : ""}0.0{span|${zeroCnt}}${numberPart.toFixed(
          0
        )}`;
      }
      return `${
        isMinus ? "-" : ""
      }0.0<span style='font-size: 8px'>${zeroCnt}</span>${numberPart.toFixed(
        0
      )}`;
    } else if (value >= 1e12) {
      return (
        (isMinus ? "-" : "") +
        Number((value / 1e12).toFixed(2)).toLocaleString() +
        "T"
      );
    } else if (value >= 1e9) {
      return (
        (isMinus ? "-" : "") +
        Number((value / 1e9).toFixed(2)).toLocaleString() +
        "B"
      );
    } else if (value >= 1e6) {
      return (
        (isMinus ? "-" : "") +
        Number((value / 1e6).toFixed(2)).toLocaleString() +
        "M"
      );
    } else if (value >= 1e3) {
      return (
        (isMinus ? "-" : "") +
        Number((value / 1e3).toFixed(2)).toLocaleString() +
        "K"
      );
    } else {
      return (isMinus ? "-" : "") + value.toLocaleString();
    }
  };

  static formatWalletAddress = (address: string, length = 5) => {
    if (!address) return "";
    if (!address.startsWith("0x")) return address;
    const prefix = address.slice(0, length);
    const suffix = address.slice(-length);
    return prefix + "..." + suffix;
  };

  static formatEmail = (email: string) => {
    const prefix = email.slice(0, 5);
    const suffix = email.slice(-10);
    return prefix + "..." + suffix;
  };

  static getExternalUrl = (url: string) => {
    if (!/^https?:\/\//i.test(url)) url = "https://" + url;
    return url;
  };

  static getTokenAddressWithChain = (chain: string, address: string) => {
    switch (chain) {
      case Chains.Ethereum:
        return `https://etherscan.io/token/${address}`;
      case Chains.Arbitrum:
        return `https://arbiscan.io/token/${address}`;
      case Chains.Binance:
        return `https://bscscan.com/token/${address}`;
      case Chains.Polygon:
        return `https://polygonscan.com/token/${address}`;
      case Chains.Optimism:
        return `https://optimistic.etherscan.io/token/${address}`;
      case Chains.Avalanche:
        return `https://snowtrace.io/token/${address}`;
      case Chains.Base:
        return `https://basescan.org/token/${address}`;
      case Chains.Cronos:
        return `https://cronoscan.com/token/${address}`;
    }
  };

  static getAddressWithChain = (chain: string, address: string) => {
    switch (chain) {
      case Chains.Ethereum:
        return `https://etherscan.io/address/${address}`;
      case Chains.Arbitrum:
        return `https://arbiscan.io/address/${address}`;
      case Chains.Binance:
        return `https://bscscan.com/address/${address}`;
      case Chains.Polygon:
        return `https://polygonscan.com/address/${address}`;
      case Chains.Optimism:
        return `https://optimistic.etherscan.io/address/${address}`;
      case Chains.Avalanche:
        return `https://snowtrace.io/address/${address}`;
      case Chains.Base:
        return `https://basescan.org/address/${address}`;
      case Chains.Cronos:
        return `https://cronoscan.com/address/${address}`;
      case Chains.Solana:
        return `https://solscan.io/account/${address}`;
    }
  };

  static getTxLinkWithChain = (chain: string, tx_hash: string) => {
    switch (chain) {
      case Chains.Ethereum:
        return `https://etherscan.io/tx/${tx_hash}`;
      case Chains.Arbitrum:
        return `https://arbiscan.io/tx/${tx_hash}`;
      case Chains.Binance:
        return `https://bscscan.com/tx/${tx_hash}`;
      case Chains.Polygon:
        return `https://polygonscan.com/tx/${tx_hash}`;
      case Chains.Optimism:
        return `https://optimistic.etherscan.io/tx/${tx_hash}`;
      case Chains.Avalanche:
        return `https://snowtrace.io/tx/${tx_hash}`;
      case Chains.Base:
        return `https://basescan.org/tx/${tx_hash}`;
      case Chains.Cronos:
        return `https://cronoscan.com/tx/${tx_hash}`;
      case Chains.Solana:
        return `https://solscan.io/tx/${tx_hash}`;
    }
  };

  static getObjectKeyFromValue = (value: string, object: any) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  static getObjectValueFromKey = (key: string, object: any) => {
    return object[key];
  };

  static exp = (val: number, max: number) => {
    const zeroToOneRange = val / max;
    return Math.ceil(Math.pow(zeroToOneRange, 5) * max);
  };

  static root = (val: number, max: number) => {
    const zeroToOneRange = val / max;
    return Math.ceil(Math.pow(zeroToOneRange, 1 / 5) * max);
  };

  static getChainIdFromChain = (chain: string) => {
    switch (chain) {
      case Chains.Ethereum:
        return 1;
      case Chains.Arbitrum:
        return 42161;
      case Chains.Binance:
        return 56;
      case Chains.Polygon:
        return 137;
      case Chains.Optimism:
        return 10;
      case Chains.Avalanche:
        return 43114;
      case Chains.Base:
        return 8453;
      case Chains.Cronos:
        return 25;
    }
  };

  static getShortChain = (chain: string) => {
    switch (chain) {
      case Chains.Ethereum:
        return "ETH";
      case Chains.Arbitrum:
        return "ARB";
      case Chains.Binance:
        return "BSC";
      case Chains.Polygon:
        return "POLY";
      case Chains.Optimism:
        return "OPT";
      case Chains.Avalanche:
        return "AVAX";
      case Chains.Base:
        return "BASE";
      case Chains.Cronos:
        return "CRO";
      case Chains.Solana:
        return "SOL";
    }
  };
}

import React from "react";
import DiscordIcon from "../Icons/DiscordIcon";
import TwitterXIcon from "../Icons/TwitterXIcon";
import YoutubeIcon from "../Icons/YoutubeIcon";
import DarkLogo from "../Logos/DarkLogo";

import "./index.scss";

function Footer() {
  return (
    <div className="footer-container desktop">
      <div className="footer-content">
        <div className="feature-content">
          <div className="paragraph1">
            <div className="subtitle">Company</div>
            <a href="">Hiring</a>
            <a
              href="mailto:support@alphanomics.io"
              target="_blank"
              rel="noreferrer"
            >
              Contact
            </a>
          </div>
          <div className="paragraph2">
            <div className="subtitle">Resources</div>
            <a
              href="https://alphanomics.io/ambassadors"
              target="_blank"
              rel="noreferrer"
            >
              Ambassadors
            </a>
            <a
              href="https://alphanomics.io/partners"
              target="_blank"
              rel="noreferrer"
            >
              Partners
            </a>
            <a
              href="https://docs.alphanomics.io/help/"
              target="_blank"
              rel="noreferrer"
            >
              Documentation
            </a>
          </div>
          <div className="paragraph">
            <a
              href="https://alphanomics.io/blog/"
              target="_blank"
              rel="noreferrer"
              className="subtitle"
            >
              Blog
            </a>
          </div>
        </div>
        <div className="logo-content">
          <a href="/">
            <DarkLogo />
          </a>
          <div className="logo-text">
            Reshaping the future of web3 data. On-chain Insights & Intelligence
            that
            <br />
            matter. Get the latest crypto analysis, updates, and reports by
            <br />
            subscribing to our free substack newsletter.
          </div>
        </div>
        <div>
          <div className="community-content">
            <div className="join-community">Join our community</div>
            <div className="social-links">
              <a
                href="https://twitter.com/Alphanomics_io"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterXIcon />
              </a>
              <a
                href="https://alphanomics.io/discord"
                target="_blank"
                rel="noreferrer"
              >
                <DiscordIcon />
              </a>
              <a
                href="https://www.youtube.com/@alphanomics"
                target="_blank"
                rel="noreferrer"
              >
                <YoutubeIcon />
              </a>
            </div>
          </div>
        </div>
        <div className="subscribe-content">
          <div className="bold">
            Want to stay up-to-date with what’s happening on-chain?
          </div>
          <div className="input-box">
            <input type="email" placeholder="Enter email address" />
            <button className="button-primary">Subscribe</button>
          </div>
          <div className="privacy-policy">
            By continuing you{"'"}re agreeing to our{" "}
            <span>Privacy Policy.</span>
          </div>
        </div>
        <div className="copyright-content">
          <div className="copyright">
            © Copyright 2024{" "}
            <a href="https://alphanomics.io" target="_blank" rel="noreferrer">
              Alphanomics.io
            </a>
          </div>
          <div className="copyright-text">
            {`The information presented on this website application (collectively referred to as the "Alphanomics") is intended solely for informational purposes. It should not be interpreted as financial advice in any form.
        Alphanomics provides information and as available, which is based on data input by users. Please note that nothing should be considered an encouragement, recommendation, endorsement, or offer by us or any third-party service provider to buy or sell any securities or financial instruments.
        This applies in any jurisdiction feeling afternoon, got no i or should it be considered a comprehensive or definitive statement on the subjects discussed or the laws pertaining to them.
        You are solely responsible for assessing the merits and risks of using any information or content from Alphanomics before making any related decisions. By using this website, you agree not to hold us, our affiliates, or any third-party service provider responsible for any potential claims for damages that may arise from decisions you make based on the information or content available through Alphanomics.
        `}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

import { useWeb3Modal } from "@web3modal/wagmi/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { mainnet } from "viem/chains";
import { useAccount, useSignMessage, useSwitchChain } from "wagmi";
import { AuthHelpers } from "../../helpers/auth";
import { authActions } from "../../redux/features/authSlice";
import { UnlockType } from "../../utils/constants";
import { AppRoutes } from "../../utils/routes";
import ConnectIcon from "../Icons/ConnectIcon";
import LockIcon from "../Icons/LockIcon";
import UpgradeIcon2 from "../Icons/UpgradeIcon2";

import "./scss/LockedTab.scss";

interface ILockedTab {
  type?: string;
  route?: string;
}

const LockedTab = ({ type, route = "" }: ILockedTab) => {
  const dispatch = useDispatch();
  const { open } = useWeb3Modal();

  const chainId = mainnet.id;
  const { chainId: currentChain } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const { data, isSuccess, signMessageAsync } = useSignMessage();
  const { address, isConnected } = useAccount();
  const [clickHandleConnect, setClickHandleConnect] = useState(false);

  const isLoggedIn = AuthHelpers.isUserLoggedIn();

  const getTypeFromRoute = () => {
    if (
      route.startsWith(AppRoutes.plan) ||
      route.startsWith(AppRoutes.profile) ||
      route.startsWith(AppRoutes.watchlist) ||
      route.startsWith(AppRoutes.smartAlert) ||
      route.startsWith(AppRoutes.leaderBoard) ||
      route.startsWith(AppRoutes.whaleTrades) ||
      route.startsWith(AppRoutes.onchainAI)
    )
      return UnlockType.Main;
    if (
      route.startsWith(AppRoutes.narrativePulse) ||
      route.startsWith(AppRoutes.smartDexStream) ||
      route.startsWith(AppRoutes.smartHoldings) ||
      route.startsWith(AppRoutes.dexAccumulations) ||
      route.startsWith(AppRoutes.cexNetTransactions)
    )
      return UnlockType.DiscoveryAlpha;
    if (
      route.startsWith(AppRoutes.perpGmxData) ||
      route.startsWith(AppRoutes.perpGmxTraderData) ||
      route.startsWith(AppRoutes.perpOpenTrades)
    )
      return UnlockType.PerpDex;
  };

  const lockedType = type ?? getTypeFromRoute();

  const handleSignMessage = async () => {
    if (currentChain !== chainId) await switchChainAsync({ chainId });
    await signMessageAsync({
      message: "Login to Alphanomics",
    });
  };

  const handleConnectWallet = async () => {
    setClickHandleConnect(true);
    if (!isConnected) await open();
    else await handleSignMessage();
  };

  useEffect(() => {
    if (clickHandleConnect && isConnected) handleSignMessage();
  }, [isConnected]);

  useEffect(() => {
    if (isSuccess) {
      const ref_code = new URLSearchParams(window.location.search).get(
        "ref_code"
      );

      dispatch(
        authActions.signin({
          wallet_address: address as string,
          signature: data as string,
          ref_code: ref_code,
          target_link:
            type === UnlockType.CreateAndUpgrade ? AppRoutes.plan : "",
        })
      );
    }
  }, [isSuccess]);

  return (
    <div className={`locked-tab ${route ? "" : "has-bg"}`}>
      <div className="unlock-content">
        <LockIcon />
        {lockedType === UnlockType.Create &&
          "Create a free account to unlock this."}
        {lockedType === UnlockType.CreateAndUpgrade &&
          "Create & Upgrade your account to unlock this data."}
        {lockedType === UnlockType.Upgrade &&
          "Upgrade your account to unlock this data."}
        {lockedType === UnlockType.Main &&
          "Create your account to access this section."}
        {lockedType === UnlockType.DiscoveryAlpha &&
          "Create & Upgrade your account to unlock this section."}
        {lockedType === UnlockType.PerpDex &&
          "Create & Upgrade your account to unlock this section."}
      </div>
      <div className="not-available">
        {lockedType === UnlockType.Create &&
          "This data is not available without an account."}
        {lockedType === UnlockType.CreateAndUpgrade &&
          "This data is not available without the correct account."}
        {lockedType === UnlockType.Upgrade &&
          "This data is not available with your current account."}
        {lockedType === UnlockType.Main &&
          "Alphanomics is an easy to use on-chain DEX data terminal which simplifies blockchain on-chain activities to provide fast, real time, cleaned & curated insights to help uncover new opportunities, token trends and actionable insights for your trading and investments."}
        {lockedType === UnlockType.DiscoveryAlpha &&
          "Discovery Alpha is our exclusive section that unlocks real time alpha on what the smartest money are doing on-chain in crypto - See everything from portfolio holdings, accumulations, CEX withdrawals or deposits and see what fresh money is doing.\n" +
            "You will need an Alphanomics account."}
        {lockedType === UnlockType.PerpDex &&
          "Perp DEX Data is our section that unlocks real time perp dex data to see what the on-chain traders are doing right at this moment.\n" +
            "You will need an Alphanomics account."}
      </div>
      {lockedType === UnlockType.Upgrade && (
        <a
          className="btn-upgrade"
          href={AppRoutes.getExactRoute(AppRoutes.plan)}
        >
          <UpgradeIcon2 /> Upgrade
        </a>
      )}
      {(lockedType === UnlockType.Create ||
        lockedType === UnlockType.CreateAndUpgrade) && (
        <>
          {isLoggedIn ? (
            <a
              className="btn-upgrade"
              href={AppRoutes.getExactRoute(AppRoutes.plan)}
            >
              <UpgradeIcon2 /> Upgrade
            </a>
          ) : (
            <a className="btn-upgrade" onClick={handleConnectWallet}>
              <ConnectIcon /> Connect
            </a>
          )}
        </>
      )}
      {(lockedType === UnlockType.Main ||
        lockedType === UnlockType.DiscoveryAlpha ||
        lockedType === UnlockType.PerpDex) && (
        <>
          {isLoggedIn ? (
            <a
              className="btn-upgrade-new"
              href={AppRoutes.getExactRoute(AppRoutes.plan)}
            >
              Upgrade Account
            </a>
          ) : (
            <a className="btn-upgrade-new" onClick={handleConnectWallet}>
              Create & Connect Account
            </a>
          )}
        </>
      )}
    </div>
  );
};

export default LockedTab;
